import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
// import dayjs from 'dayjs';
// import relativeTime from 'dayjs/plugin/relativeTime';
// import PropTypes from 'prop-types';
import MyButton from './MyButton';
// import DeleteScream from './DeleteScream';
import ScreamDialog from './ScreamDialog';
import LikeButton from './LikeButton';
// MUI Stuff
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
// Icons
import ChatIcon from '@material-ui/icons/Chat';
// Redux
// import { connect } from 'react-redux';

const styles = {
  card: {
    position: 'relative',
    display: 'flex',
    marginBottom: 20
  },
  image: {
    minWidth: 200
  },
  content: {
    padding: 25,
    objectFit: 'cover'
  }
};

class Scream extends Component {
  render() {
    // dayjs.extend(relativeTime);
    const {
      classes,
      scream: {
        id,
        title,
        desc,
        founder,
        sponsor,
        ceo,
        members,
        img
      },

    } = this.props;
console.log("img:", img)
    return (
      <Card className={classes.card}>
        <CardMedia
          image={img}
          title="Profile image"
          className={classes.image}
        />
        <CardContent className={classes.content}>
          <Typography
            variant="h5"
            component={Link}
            // to={`/users/${userHandle}`}
            color="primary"
          >
            {title}
          </Typography>
          {/* {deleteButton} */}
          <Typography variant="body2" color="textSecondary">
            {/* {dayjs(createdAt).fromNow()} */}
          </Typography>
          <Typography variant="body1">{desc}</Typography>
          <LikeButton screamId={id} />
          <span>{id} Likes</span>
          <MyButton tip="comments">
            <ChatIcon color="primary" />
          </MyButton>
          <span>{sponsor} comments</span>
          <ScreamDialog
            screamId={id}
            userHandle={title}
            openDialog={this.props.openDialog}
          />
        </CardContent>
      </Card>
    );
  }
}

// Scream.propTypes = {
//   user: PropTypes.object.isRequired,
//   scream: PropTypes.object.isRequired,
//   classes: PropTypes.object.isRequired,
//   openDialog: PropTypes.bool
// };


export default withStyles(styles)(Scream) //connect(mapStateToProps)(withStyles(styles)(Scream));
