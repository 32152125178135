import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import * as actions from "../../store/actions";

// import SearchBarCustom from "../../components/ProductList/SearchBarCustom/SearchBarCustom";
// import SortButton from "../../components/ProductList/SortButton/SortButton";
// import FilterButton from "../../components/ProductList/FilterButton/FilterButton";
// import GroupButton from "../../components/ProductList/GroupButton/GroupButton";
import CourseCardItem from "../../components/ProductList/CourseCard/CourseCard";
import Spinner from "../../components/UI/Spinner/Spinner";
// import DataLength from "../../components/DataDisplay/DataLength";
import Scream from '../../components/scream/Scream';
import CourseList from "./CourseList";


const Courses = (props) => {
  let { courseList } = props;

  let { products, onfetchProducts } = props;

  // console.log("Apps Products", products)

  // const { loading } = props;
  const [loading, setLoading]  = useState(true);
  const { onfetchCourses } = props;

  // const [courseType, setCourseType] = useState("all");
  // const [sortBy, setSortBy] = useState("id");
  // const [group, setGroup] = useState("GP08");
  // const [keyWord, setKeyWord] = useState(null);

  // useEffect(() => {
  //   onfetchCourses(courseType, group, keyWord);
  // }, [onfetchCourses, courseType, group, keyWord]);

//My changes
// useEffect(() => {
//   onfetchProducts();
// }, []);
// const [CompanyData, setCompanyData] = useState({});
const [companyList, setCompanyList] = useState(null);



const getCompanyList=()=>{
  fetch('https://daneshpark-workshop.herokuapp.com/products')
    .then(function(response){
      console.log(response)
      return response.json();
    })
    .then(function(myJson) {
      // console.log(myJson);          
      // setData(myJson)  
      const newCompanyList = [];
      
      myJson.forEach((frame, index) => {
        newCompanyList[index] = {
        id: frame._id,
        title: frame.name,
        description: frame.content,
        category: frame.price,
        members: frame.members,
        image:  frame.image.url
      };
    });
    setCompanyList(newCompanyList);
    setLoading(false)
    // maxfrm.current = Object.keys(newCompanyList).length;
    // console.log("Company List:", newCompanyList);  

    });
}

useEffect(() => {
  getCompanyList()
 
}, []);



  let courseListRender = <Spinner />;
  // if (!loading && courseList.length > 0) {
  //   // console.log("courseList:", courseList)
  //   courseListRender = courseList.map((course, index) => (
  //     <Grid item key={index}>
  //       <CourseCardItem course={course} />
  //     </Grid>
  //   ));
  // }
// console.log("loading", loading)
  if (!loading && companyList.length > 0) {
    // console.log("courseList:", courseList)
    courseListRender = companyList.map((course, index) => (
      <Grid item key={index}>
        <CourseCardItem course={course} />
        {/* <Scream key={index} scream={course} />) */}
      </Grid>
    ));
  }

  return (
    <Box>
      {companyList && 
<CourseList list ={companyList}/>}

      {/* <Grid container spacing={2} justify="center">
        {courseListRender}
      </Grid> */}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    courseList: state.courses.courseList,
    loading: state.products.loading,

    //My changes
    products: state.products.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchCourses: (courseType, group, keyWord) =>
      dispatch(actions.fetchCourses(courseType, group, keyWord)),
    onfetchProducts: () => dispatch(actions.fetchProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
