// import { ActionTypes } from "../contants/action-types";
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  products: [],
  productCat: [],
  productDetail: [],
  userDetail: [],
  loading: false,
};


export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PRODUCTS:
      return { ...state, products: action.payload };
    case actionTypes.FETCH_PRODUCTS:
      return { ...state, products: action.payload };


    //Mychanges
    // Course Index
    case actionTypes.FETCH_PRODUCT_CAT_START:
      return fetchProductCatStart(state, action);
    case actionTypes.FETCH_PRODUCT_CAT_SUCCESS:
      return fetchProductCatSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_CAT_FAIL:
      return fetchProductCatFail(state, action);

    default:
      return state;
  }
};

export const selectedProductReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case actionTypes.SELECTED_PRODUCT:
      return { ...state, ...payload };
    case actionTypes.REMOVE_SELECTED_PRODUCT:
      return {};
    default:
      return state;
  }
};



// ----------------- Product Category------------------ //
const fetchProductCatStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchProductCatSuccess = (state, action) => {
  return updateObject(state, {
    productCat: action.productCat,
    loading: false,
  });
};

const fetchProductCatFail = (state, action) => {
  return updateObject(state, { loading: false });
};


export default productReducer;
