import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import * as actions from "../../store/actions";

// import CourseCardItem from "../../components/ProductList/CourseCard/CourseCard";
// import Spinner from "../../components/UI/Spinner/Spinner";
import CourseList from "./CourseList";


const Courses = (props) => {
  const [loading, setLoading]  = useState(true);

const [companyList, setCompanyList] = useState(null);



const getCompanyList=()=>{
  fetch('https://daneshpark-workshop.herokuapp.com/products')
    .then(function(response){
      console.log(response)
      return response.json();
    })
    .then(function(myJson) {
      // console.log(myJson);          
      // setData(myJson)  
      const newCompanyList = [];
      
      myJson.forEach((frame, index) => {
        newCompanyList[index] = {
        id: frame._id,
        title: frame.name,
        description: frame.content,
        category: frame.price,
        members: frame.members,
        image:  frame.image.url
      };
    });
    setCompanyList(newCompanyList);
    setLoading(false)
    // maxfrm.current = Object.keys(newCompanyList).length;
    // console.log("Company List:", newCompanyList);  

    });
}

useEffect(() => {
  getCompanyList()
 
}, []);



  return (
    <Box>
      {companyList && 
<CourseList list ={companyList}/>}
      Tour
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    courseList: state.courses.courseList,
    loading: state.products.loading,

    //My changes
    products: state.products.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchCourses: (courseType, group, keyWord) =>
      dispatch(actions.fetchCourses(courseType, group, keyWord)),
    onfetchProducts: () => dispatch(actions.fetchProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
